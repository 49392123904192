import { Outlet, useNavigate } from "react-router-dom"
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from './common/storeContext'
import { useMiddletier } from "./common/middletier";











import ContactUs from './sections/sidebar_contact_us/template_01';





import Announcement from './sections/announcement/template_01';





import Header from './sections/header/template_03';





import Footer from './sections/footer/template_01';







import Alert from './components/Alert';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './setting.css';
import { useEffect, useState } from "react";

function App() {
  const appState = useStoreState()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate();
  const [alertShow, setAlertShow] = useState({});

  const { queries, mutation, query } = useMiddletier()

  useEffect(() => {
    if (appState.loginWithToken === true && appState.loggedIn === false) {
      mutation([
        {
          index: 'loginWithTokenByMember',
          method: 'loginWithTokenByMember',
          params: [],
          attributes: []
        }
      ])
        .then(({ data }) => {
          // console.log('data: ', data['loginWithTokenByMember'])
          appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data['loginWithTokenByMember'] } })
        })
        .catch((error) => {
          console.error(error)
          appDispatch({ type: APP_STORE_ACTION.SET_LOGIN_WITH_TOKEN, payload: false })


        })
    }
  }, [mutation, appDispatch, appState, navigate])

   // CHECK META PIXEL ID
 useEffect(() => {
  console.log('appState.user?.affiliate', appState.user?.affiliate)
  // IF GOT UNDER AFFILIATE
  if (appState.user?.affiliate !== null && typeof appState.user?.affiliate !== 'undefined') {
    query({
      method: 'affiliate',
      params: [
        { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.affiliate },
      ],
      attributes: ['meta_pixel']
    })
      .then(({ data }) => {
        const getMetaPixelByAffiliate = data['affiliate']?.meta_pixel ?? ''
        console.log('getMetaPixelByAffiliate', getMetaPixelByAffiliate)
        if (getMetaPixelByAffiliate !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: getMetaPixelByAffiliate })
        } else {
         // USE DEFAULT PIXEL
         if (window.DEFAULT_PIXEL_ID !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
        } else {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
        }
        }

      })
      .catch((error) => {
        console.error(error)
        // USE DEFAULT PIXEL
        if (window.DEFAULT_PIXEL_ID !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
        } else {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
        }
      })
  } else {

    const getLastAffiliate = localStorage.getItem('aff_id');

    // IF USE REGISTER AFFILIATE LINK ACCESS WEBSITE
    if (getLastAffiliate !== null) {
      console.log('getLastAffiliate', getLastAffiliate)
      const checkAffiliate = getLastAffiliate?.length >= 6 ? getLastAffiliate?.substring(0, 6) : getLastAffiliate?.padEnd(6, '0');
      query({
        method: 'getAffiliateMetaPixel',
        params: [
          { code: 'code', graphqlType: 'String', required: true, value: checkAffiliate },
        ],
        attributes: []

      }).then(({ data }) => {

        const getMetaPixelByAffiliate = data['getAffiliateMetaPixel'] ?? ''

        if (getMetaPixelByAffiliate !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: getMetaPixelByAffiliate })
        } else {
            // USE DEFAULT PIXEL
            if (window.DEFAULT_PIXEL_ID !== '') {
              appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
            } else {
              appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
            }
        }

      }).catch((error) => {
        console.log('error: ', error)
        // USE DEFAULT PIXEL
        if (window.DEFAULT_PIXEL_ID !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
        } else {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
        }
      })
    } else {
      // USE DEFAULT PIXEL
      if (window.DEFAULT_PIXEL_ID !== '') {
        appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
      }
    }
  }

}, [query, appState?.user?.affiliate, appDispatch])


// PIXEL ID INSERT NO SCRIPT
useEffect(() => {
  console.log('window fbq', window.fbq)
  if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
    window.fbq('init', appState?.pixelID);
    console.log('init', appState?.pixelID)

    window.fbq("track", "PageView")
    if (!document.querySelector(`noscript[data-pixel-id="${appState?.pixelID}"]`)) {
      const noscript = document.createElement('noscript');
      noscript.setAttribute('data-pixel-id', appState?.pixelID);
      noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${appState?.pixelID}&ev=PageView&noscript=1" />`;
      document.body.appendChild(noscript);
    }
  }
}, [appState?.pixelID])

  useEffect(() => {
    queries([
      {
        index: 'siteSettings',
        method: 'siteSettings',
        params: [],
        attributes: []
      }
    ])
      .then(({ data }) => {
        console.log('data: ', data['siteSettings'])
        appDispatch({ type: APP_STORE_ACTION.SET_SITE_SETTINGS, payload: { ...data['siteSettings'] } })
      })
      .catch((error) => {
        console.error(error)
      })
  }, [queries, appDispatch])

  // CHECK META PIXEL ID
  useEffect(() => {
    console.log('appState.user?.affiliate', appState.user?.affiliate)
    // IF GOT UNDER AFFILIATE
    if (appState.user?.affiliate !== null && typeof appState.user?.affiliate !== 'undefined') {
      query({
        method: 'affiliate',
        params: [
          { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.affiliate },
        ],
        attributes: ['meta_pixel']
      })
        .then(({ data }) => {
          const getMetaPixelByAffiliate = data['affiliate']?.meta_pixel ?? ''
          console.log('getMetaPixelByAffiliate', getMetaPixelByAffiliate)
          if (getMetaPixelByAffiliate !== '') {
            appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: getMetaPixelByAffiliate })

            // window.fbq('init', getMetaPixelByAffiliate);
            // console.log(`init ${getMetaPixelByAffiliate}`)
          }

        })
        .catch((error) => {
          console.error(error)
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
        })
    } else {

      const getLastAffiliate = localStorage.getItem('aff_id');

      // IF USE REGISTER AFFILIATE LINK ACCESS WEBSITE
      if (getLastAffiliate !== null) {
        console.log('getLastAffiliate', getLastAffiliate)
        const checkAffiliate = getLastAffiliate?.length >= 6 ? getLastAffiliate?.substring(0, 6) : getLastAffiliate?.padEnd(6, '0');
        query({
          method: 'getAffiliateMetaPixel',
          params: [
            { code: 'code', graphqlType: 'String', required: true, value: checkAffiliate },
          ],
          attributes: []

        }).then(({ data }) => {

          const getMetaPixelByAffiliate = data['getAffiliateMetaPixel'] ?? ''

          if (getMetaPixelByAffiliate !== '') {

            appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: getMetaPixelByAffiliate })
          }

        }).catch((error) => {
          console.log('error: ', error)
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: '' })
        })
      } else {
        // USE DEFAULT PIXEL
        if (window.DEFAULT_PIXEL_ID !== '') {
          appDispatch({ type: APP_STORE_ACTION.SET_PIXEL_ID, payload: window.DEFAULT_PIXEL_ID })
        }
      }
    }

  }, [query, appState?.user?.affiliate, appDispatch])


  // PIXEL ID INSERT NO SCRIPT
  useEffect(() => {
    console.log('window fbq', window.fbq)
    if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
      window.fbq('init', appState?.pixelID);
      console.log('init', appState?.pixelID)

      window.fbq("track", "PageView")
      if (!document.querySelector(`noscript[data-pixel-id="${appState?.pixelID}"]`)) {
        const noscript = document.createElement('noscript');
        noscript.setAttribute('data-pixel-id', appState?.pixelID);
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${appState?.pixelID}&ev=PageView&noscript=1" />`;
        document.body.appendChild(noscript);
      }
    }
  }, [appState?.pixelID])


  const onHideAlert = () => {
    if (appState.alert.type && appState.alert.type === APP_STORE_ACTION.UNAUTHENTICATED) {
      appDispatch({ type: APP_STORE_ACTION.LOGOUT })
      navigate('/')
      appDispatch({ type: APP_STORE_ACTION.SHOW_ALERT, payload: false })
    }
    else {
      if (appState.alert?.forwardUrl) {
        navigate(`${appState.alert?.forwardUrl}`)
      }

      if (appState.alert?.onHide) {
        appState.alert?.onHide()
      }

      appDispatch({ type: APP_STORE_ACTION.SHOW_ALERT, payload: false })
    }
  }


  return (
    <div className="App">

      <Alert show={appState.alert.open ?? false} onHide={onHideAlert} message={appState.alert.description} typeAlert={appState.alert.typeAlert} title={appState.alert.title} />



















      <Header desktopToggle={true} mobileToggle={
        true} navIconToggle={false} dropdownGameProviderNameToggle={true} timeToggle={false} dropdownImageSize={`1x1`} annLogoToggle={true} annHeaderToggle={false} headerMenuPosition={`start`} sidebarToggle={true} enableDropdownHoverImage={false} />











      <Outlet />











      <ContactUs contactHeader={true} />













      <Footer desktopToggle={true} mobileToggle={
        true} game_licenseToggle={true} certificationToggle={
          false} payment_methodToggle={true}
        suggested_browserToggle={false} follow_usToggle={
          true} responsible_gamingToggle={false} shareHolderToggle={false}
        footer_titleToggle_desktop={true} footer_titleToggle_mobile={
          false} footer_infoToggle={false} position={``} bottomMenuToggle={false} gameProvidersToggle={false} providerRowDesktop={10} providerRowMobile={5} infoPosition={`center`} folderImage={``} />








    </div>
  );
}

export default App;


